import { ColumnDef, Row } from '@tanstack/react-table';
import SelectionTable from '../table/SelectionTable';
import { useListContext } from './ListContext';
import { FC } from 'react';

interface ListResultsProps<TItem> {
    columns: ColumnDef<TItem, string>[];
    selection?: {
        selected: string[];
        onChange: (selected: string[]) => void;
        keyProperty: keyof TItem;
    };
    maxSelected?: number;
    expandedRow?: FC<{ row: Row<TItem> }>;
}

export const ListResults = <TItem extends { id: string }>({
    selection,
    columns,
    maxSelected,
    expandedRow,
}: ListResultsProps<TItem>) => {
    const { items } = useListContext();

    return (
        <SelectionTable<TItem>
            data={items as TItem[]}
            columns={columns}
            selection={selection}
            maxSelected={maxSelected}
            expandedRow={expandedRow}
        />
    );
};
