import {
    Checkbox,
    Table as ChakraTable,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Spinner,
} from '@chakra-ui/react';
import {
    flexRender,
    ColumnDef,
    getCoreRowModel,
    useReactTable,
    Row,
    getExpandedRowModel,
    ExpandedState,
} from '@tanstack/react-table';
import { FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from '@/hooks/useToast';

interface TableProps<TItem> {
    data?: TItem[];
    columns: ColumnDef<TItem, string>[];
    isLoading?: boolean;
    selection?: {
        selected: string[];
        onChange: (selected: string[]) => void;
        keyProperty: keyof TItem;
    };
    maxSelected?: number;
    expandedRow?: FC<{ row: Row<TItem> }>;
}

const SelectionTable = <TItem,>({
    columns,
    data = [],
    isLoading = false,
    selection,
    maxSelected,
    expandedRow,
}: TableProps<TItem>) => {
    const { t } = useTranslation('common');
    const toast = useToast();
    const isExpandable = !!expandedRow;
    const isSelectable = !!selection;

    const rowSelection = useMemo(
        () =>
            selection &&
            selection.selected.reduce((acc, id) => ({ ...acc, ...{ [id]: true } }), {}),
        [selection],
    );

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: isExpandable ? getExpandedRowModel() : undefined,
        onExpandedChange: isExpandable ? setExpanded : undefined,
        getRowCanExpand: () => isExpandable,
        enableRowSelection: isSelectable,
        onStateChange: isSelectable
            ? (updater) => {
                  const update =
                      typeof updater === 'function' ? updater(table.getState()) : updater;

                  const selectedRows = Object.keys(update.rowSelection);
                  if (maxSelected && selectedRows.length > maxSelected) {
                      toast({
                          title: t('max_selected', { max: maxSelected }),
                          status: 'warning',
                      });
                      const selectedKeys = selectedRows.slice(0, maxSelected);
                      selection.onChange(selectedKeys);
                  } else {
                      selection.onChange(Object.keys(selectedRows));
                  }
              }
            : undefined,
        getRowId: isSelectable
            ? (row, relativeIndex, parent) =>
                  parent
                      ? [parent.id, row[selection.keyProperty]].join('.')
                      : (row[selection.keyProperty] as string)
            : undefined,
        state: {
            expanded,
            rowSelection,
        },
    });

    return (
        <TableContainer>
            <ChakraTable>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {isSelectable && (
                                <Th>
                                    <Checkbox
                                        isChecked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                                    />
                                </Th>
                            )}
                            {headerGroup.headers.map((header) => (
                                <Th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        table.getRowModel().rows.map((row) => (
                            <Fragment key={row.id}>
                                <Tr>
                                    {isSelectable && (
                                        <Td>
                                            <Checkbox
                                                isChecked={row.getIsSelected()}
                                                onChange={row.getToggleSelectedHandler()}
                                            />
                                        </Td>
                                    )}
                                    {row.getVisibleCells().map((cell) => (
                                        <Td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </Td>
                                    ))}
                                </Tr>
                                {isExpandable && row.getIsExpanded() && (
                                    <Tr>
                                        <Td colSpan={row.getAllCells().length}>
                                            {expandedRow({ row })}
                                        </Td>
                                    </Tr>
                                )}
                            </Fragment>
                        ))
                    )}
                    {!isLoading && table.getRowModel().rows.length === 0 && (
                        <Tr>
                            <Td
                                color="gray.500"
                                textAlign="center"
                                colSpan={table.getVisibleFlatColumns().length}
                            >
                                {t('no_data')}
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </ChakraTable>
        </TableContainer>
    );
};

export default SelectionTable;
